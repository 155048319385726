import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import TipContent from "./TipContent";
import TipImage from "./TipImage";

type ReservationTipCharge = {
  amount: number;
  rate: number;
};

type Props = {
  reservationTipCharge?: ReservationTipCharge;
  initialShow: boolean;
};

const TipDetailModal: React.FC<Props> = ({
  reservationTipCharge,
  initialShow,
}) => {
  const [isShow, setShow] = useState(initialShow);

  return (
    <>
      <Modal size="mg" show={isShow} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="container">
            <div className="my-2">
              <h2 className="text-center c-titleL mb-3">
                {window.i18n.t("components.user.tips.show.container.title")}
              </h2>
              <p className="text-center fw-bold">
                {window.i18n.t(
                  "components.user.tips.show.container.sub_title_1",
                )}
                <br />
                {window.i18n.t(
                  "components.user.tips.show.container.sub_title_2",
                )}
              </p>
              <TipContent reservationTipCharge={reservationTipCharge} />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setShow(true);
        }}
      >
        <TipImage rate={reservationTipCharge.rate} />
      </a>
    </>
  );
};

export default TipDetailModal;
