import React, { useRef, useState } from "react";
import Rails from "rails-ujs";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";

import StripeCardField from "../../StripeCardField";
import TipContent from "./TipContent";

import { TipRate } from "../../../types/tip";

type Restaurant = {
  slug: string;
  restaurantName: string;
  thumbUrl: string;
};

type Reservation = {
  uid: string;
  amount: number;
  startsAt: Date;
  guestsCount: number;
  courseTitle: string;
};

type StripeSourceCard = {
  id: string;
  last4: string;
  brand: string;
  brandImagePath: string;
};

type StripeCardFieldProps = {
  stripePublicKey: string;
  stripeSourceCard: StripeSourceCard | null;
};

type Props = {
  restaurant: Restaurant;
  reservation: Reservation;
  stripeCardField: StripeCardFieldProps | null;
  tipRates: TipRate[];
};

const DEFAULT_DECIMAL_RATE: number = 0.05;

const New: React.FC<Props> = ({
  restaurant,
  reservation,
  stripeCardField,
  tipRates,
}) => {
  const [rate, setRate] = useState<number>(DEFAULT_DECIMAL_RATE);
  const [amount, setAmount] = useState<number>(reservation.amount * rate);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const cardRef = useRef<HTMLInputElement>(null);

  const handleChangeRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRate(parseFloat(e.target.value));
    setAmount(reservation.amount * parseFloat(e.target.value));
  };

  const handlePay = () => {
    setLoading(true);

    axios
      .post(`/user/reservations/${reservation.uid}/tips`, {
        authenticity_token: Rails.csrfToken(),
        amount: reservation.amount,
        rate: rate,
        displayed_stripe_source_id: cardRef.current?.props.stripeSourceCard.id,
      })
      .then(() => {
        window.location.href = `/user/reservations/${reservation.uid}?notice=true`;
      })
      .catch((e) => {
        setErrorMessage(e.response.data.message);
      })
      .finally(() => {
        setShowModal(false);
        setLoading(false);
      });
  };

  return (
    <>
      {errorMessage && (
        <div className="c-flash c-flash-alert" role="alert">
          {errorMessage}
        </div>
      )}
      <div className="container">
        <div className="my-5">
          <div className="p-rsvTip_details c-section">
            <h2 className="c-titleL text-center">
              {window.i18n.t("components.user.tips.new.container.title")}
            </h2>
            <p className="text-center mb-3">
              {window.i18n.t("components.user.tips.new.container.sub_title")}
            </p>

            <TipContent />

            <div className="p-rsvTip_select">
              <h3 className="c-titleM mb-3">
                {window.i18n.t(
                  "components.user.tips.new.container.tip_amount_to_pay_button_text",
                )}
              </h3>

              <div className="p-rsvTip_select_wrap">
                {tipRates.map((tipRate: TipRate, index: number) => (
                  <div className="p-rsvTip_select_item" key={index}>
                    <label htmlFor={`tip_rate_${tipRate.percentageRate}`}>
                      <input
                        type="radio"
                        id={`tip_rate_${tipRate.percentageRate}`}
                        name="tip_rate"
                        value={tipRate.decimalRate}
                        onChange={handleChangeRate}
                        checked={rate === tipRate.decimalRate}
                      />
                      <div className="p-rsvTip_select_content">
                        <img
                          src={require(
                            `../../../images/tips/tip_bottle_${tipRate.percentageRate}.png`,
                          )}
                          alt={`${tipRate.percentageRate}%`}
                        />
                        <div className="p-rsvTip_select_num">{`${tipRate.percentageRate}%`}</div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>

              <p className="p-rsvTip_amount">JPY {amount}</p>
            </div>

            <div className="mb-4">
              <h2 className="c-titleM">
                {window.i18n.t(
                  "components.user.new_reservation_requests.form_group.rsv_request_item.payment_method.title",
                )}
              </h2>
              <StripeCardField
                stripePublicKey={stripeCardField?.stripePublicKey}
                stripeSourceCard={stripeCardField?.stripeSourceCard}
                isTemporaryReservation={false}
                ref={cardRef}
              />
            </div>

            <div className="text-center mb-3">
              <p>
                {window.i18n.t(
                  "components.user.tips.new.container.agree_to_terms",
                )}
              </p>
              <Button
                className="btn-primary"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {window.i18n.t(
                  "components.user.tips.new.container.proceed_to_pay_button_text",
                )}
              </Button>
            </div>

            <div className="p-rsvTip_terms">
              TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU
              TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU TBU
            </div>
          </div>

          <Modal
            show={showModal}
            // size={"sm"}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {window.i18n.t("components.user.tips.new.modal.title")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {window.i18n.t("components.user.tips.common.tip_description", {
                  amount: amount,
                  restaurant: restaurant.restaurantName,
                })}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                disabled={loading}
                onClick={() => setShowModal(false)}
              >
                {window.i18n.t(
                  "components.user.tips.new.modal.cancel_button_text",
                )}
              </Button>
              <Button
                variant="outline-primary"
                onClick={handlePay}
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm me-2" />
                )}
                {window.i18n.t(
                  "components.user.tips.new.modal.complete_button_text",
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default New;
