import React from "react";
import Countdown from "react-countdown";

type Props = {
  timeLimit: Date;
};

const Timer: React.FC<Props> = ({ timeLimit }) => {
  console.log(timeLimit);
  const formattedTime = (time: object) => {
    const days = time.days;
    const hours = time.hours;
    const minutes = time.minutes.toString().padStart(2, "0");
    const seconds = time.seconds.toString().padStart(2, "0");

    if (hours > 0) {
      const totalHours = days > 0 ? days * 24 + hours : hours;
      return `${totalHours.toString().padStart(2, "0")}:${minutes}:${seconds}`;
    } else {
      return `${minutes}:${seconds}`;
    }
  };

  return (
    <div className="timer">
      <i className="fas fa-stopwatch me-1"></i>
      <Countdown
        date={new Date(timeLimit)}
        renderer={(props) => <div>{formattedTime(props)}</div>}
      />
    </div>
  );
};

export default Timer;
