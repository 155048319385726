import React from "react";
import TipImage from "./TipImage";

type ReservationTipCharge = {
  amount: number;
  rate: number;
};

type Props = {
  reservationTipCharge?: ReservationTipCharge;
};

const TipContent: React.FC<Props> = ({ reservationTipCharge }) => {
  return (
    <>
      {reservationTipCharge && (
        <div className="p-tipModal_top">
          <div className="p-tipModal_img">
            <TipImage rate={reservationTipCharge.rate} />
          </div>
          <p>JPY {reservationTipCharge.amount}</p>
        </div>
      )}
    </>
  );
};

export default TipContent;
