import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import snakecaseKeys from "snakecase-keys";
import { SearchEndpointParams } from "../../types/filter_params";
import { MealCategory } from "../../types/meal_category";
import { Prefecture } from "../../types/prefecture";
import {
  bpMobile,
  maxGuestsCount,
  mealCategories,
} from "../../utils/constants";
import cookies from "../../utils/cookies";
import { monthInMs } from "../../utils/constants";
import {
  calcDaysBetween2days,
  getDatesBetween,
  newDateFromDateString,
  toISODateString,
} from "../../utils/dates";
import differenceBy from "lodash/differenceBy";
import { getDateFnsLocale } from "../../utils/locale";

interface Props {
  prefectures: Prefecture[];
  collapseOnMobile: boolean;
  minDate: string;
  defaultParams?: SearchEndpointParams;
}

/**
 * トップページ上部に表示される検索用のFormm実装
 * 選択された都道府県のURLに遷移 (/pref/:prefecture)
 * それ以外の条件項目はクエリパラメータ形式で実装
 */
const SearchBox: React.FC<Props> = ({
  prefectures,
  collapseOnMobile,
  minDate,
  defaultParams = {},
}) => {
  const [prefecture, setPrefecture] = useState<Prefecture>();
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [guestsCount, setGuestsCount] = useState<number>();
  const [mealCategory, setMealCategory] = useState<MealCategory>();
  const [queryString, setQueryString] = useState<string>();
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(bpMobile);
  const [excludedDates, setExcludedDates] = useState<Date[]>([]);
  const [requiredDate, setRequiredDate] = useState<boolean>(false);
  const [requiredTime, setRequiredTime] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const datePicker = useRef<DatePicker>();

  useEffect(() => {
    responsive();
    fetchAvailableDates(new Date());
    window.addEventListener("resize", responsive);
    return () => {
      window.removeEventListener("click", closeForm);
      window.removeEventListener("resize", responsive);
    };
  }, []);

  useEffect(() => {
    if (guestsCount > 0) {
      cookies.set("guests_count", guestsCount.toString());
    } else {
      cookies.set("guests_count", "");
    }
  }, [guestsCount]);

  const translateToQuery = (values: {
    startDate: Date | undefined;
    endDate: Date | undefined;
    guestsCount: number | undefined;
    mealCategory: MealCategory | undefined;
  }): SearchEndpointParams => {
    const params = { ...defaultParams };
    if (values.startDate) {
      params["startDate"] = toISODateString(values.startDate);
    }
    if (values.endDate) {
      params["endDate"] = toISODateString(values.endDate);
    }
    if (values.guestsCount > 0) {
      params["guestsCount"] = values.guestsCount.toString();
    }
    if (values.mealCategory) {
      params["mealCategory"] = values.mealCategory.toString();
    }
    return params;
  };

  const buildAndSetQueryString = (key?: string, value?: string) => {
    const query = translateToQuery({
      startDate,
      endDate,
      guestsCount,
      mealCategory,
    });
    if (key && value) {
      query[key] = value;
    } else if (key) {
      // valueがnullの場合はパラメータから削除
      delete query[key];
    }
    const searchParam = new URLSearchParams(query);
    searchParam.sort();
    setQueryString(searchParam.toString());
  };

  const toggleDatePicker = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    datePicker.current.setOpen(!datePicker.current.isCalendarOpen());
  };

  const responsive = () => {
    setWidth(window.innerWidth);
  };

  const openForm = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    window.addEventListener("click", closeForm);
    setIsFormOpen(true);
  };

  const closeForm = (
    event: Event | React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    window.removeEventListener("click", closeForm);
    setIsFormOpen(false);
  };

  const fetchAvailableDates = async (month: Date) => {
    const url = "/candidate_dates";
    try {
      const today = new Date();
      const toMonth = new Date(month.getTime() + monthInMs);
      const allDates = getDatesBetween(today, toMonth);
      setExcludedDates(allDates);
      const response = await axios.get(url, {
        params: snakecaseKeys({
          startDate: toISODateString(today),
          endDate: toISODateString(new Date(month.getTime() + monthInMs)),
        }),
      });
      if (response.status === 200) {
        const availableDates = Array.from(
          response.data.requestAvailableDates.concat(
            response.data.irsAvailableDates || [],
          ),
        ).map((dateStr) => newDateFromDateString(dateStr));
        const excludedDates = differenceBy(
          allDates,
          availableDates,
          toISODateString,
        );
        setExcludedDates(excludedDates);
        if (
          startDate &&
          endDate &&
          !response.data.find(
            (availableDate) => availableDate === toISODateString(startDate),
          ) &&
          !response.data.find(
            (availableDate) => availableDate === toISODateString(endDate),
          )
        ) {
          setStartDate(undefined);
          setEndDate(undefined);
        }
      } else {
        return Promise.reject(response);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  /**
   *
   * @param e
   */
  const doSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!prefecture) {
      alert("Please select the prefecture.");
      e.preventDefault();
      return false;
    }
    if (
      (!!startDate && !!endDate && !mealCategory) ||
      (!startDate && !endDate && !!mealCategory)
    ) {
      let txt = "";
      if (!startDate && !endDate) {
        txt = "Date";
        setRequiredDate(true);
        setRequiredTime(false);
      } else {
        txt = "Time";
        setRequiredDate(false);
        setRequiredTime(true);
      }
      alert(`Please select the ${txt}.`);
      e.preventDefault();
      return false;
    }
  };

  const handleDatesChanges = (dates: [Date, Date]) => {
    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      const days = calcDaysBetween2days(startDate, endDate);
      if (days > 6) {
        alert("Please select within one week.");
        setStartDate(null);
        setEndDate(null);
        return;
      }
    }
    setStartDate(startDate);
    setEndDate(endDate);
    buildAndSetQueryString(
      "startDate",
      startDate ? toISODateString(startDate) : null,
    );
    buildAndSetQueryString(
      "endDate",
      endDate ? toISODateString(endDate) : null,
    );
    setRequiredDate(!startDate && !endDate && !!mealCategory);
    setRequiredTime(!!startDate && !!endDate && !mealCategory);
  };

  const getWeekStartsOn = () => {
    const path = window.location.pathname;
    if (path.startsWith("/ko")) return 0; // 日曜始まり
    return 1;
  };

  return (
    <>
      <div
        className="c-spSearchBox"
        style={{
          display: collapseOnMobile && width < bpMobile ? "block" : "none",
        }}
        onClick={openForm}
      >
        <div className="c-spSearchBox_pref">{prefecture?.name}</div>
        <div className="c-spSearchBox_content">
          <span className="c-spCollapsedSearch_date">
            {startDate?.toDateString()} - {endDate?.toDateString()}
          </span>
          <span>/</span>
          <span className="c-spCollapsedSearch_pSize">
            {guestsCount}{" "}
            {window.i18n.t(
              "components.restaurants.top_search_box.sp_searchbox.guests",
            )}
          </span>
          <span>/</span>
          <span className="c-spCollapsedSearch_tSlot">{mealCategory}</span>
        </div>
        <i className="fas fa-angle-down"></i>
      </div>

      <div
        className="c-topSearchBox"
        style={{
          display:
            collapseOnMobile && width < bpMobile && !isFormOpen
              ? "none"
              : "block",
        }}
      >
        <div
          className="c-topSearchBox_editCond"
          style={{
            display: collapseOnMobile && width < bpMobile ? "block" : "none",
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <span onClick={closeForm}>
            <i className="fas fa-times"></i>
          </span>
          <div className="c-topSearchBox_editCond_title">
            {window.i18n.t(
              "components.restaurants.top_search_box.top_searchbox.edit_conditions.title",
            )}
          </div>
        </div>
        <form className="c-topSearchBox_itemWrap">
          <div
            className="c-topSearchBox_item"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="c-topSearchBox_item_icon">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <label htmlFor="prefecture">{window.i18n.t("prefecture")}</label>
            <p className="c-topSearchBox_ph">
              {prefecture ? (
                <span>
                  {window.i18n.t(
                    `prefectures.${prefecture.name.toLowerCase()}`,
                  )}
                </span>
              ) : (
                <span>
                  {window.i18n.t(
                    "components.restaurants.top_search_box.top_searchbox.prefecture.option.default",
                  )}
                </span>
              )}
            </p>
            <i className="fas fa-angle-down"></i>
            <select
              id="prefecture"
              className="c-topSearchBox_select"
              value={prefecture?.uid}
              onChange={(event) => {
                setPrefecture(
                  prefectures.find(
                    (element) => element.uid === event.target.value,
                  ) || undefined,
                );
              }}
              aria-label="prefecture"
            >
              <option value=""></option>
              {prefectures.map((option) => (
                <option key={option.uid} value={option.uid}>
                  {window.i18n.t(`prefectures.${option.name.toLowerCase()}`)}
                </option>
              ))}
            </select>
          </div>

          <div
            className="c-topSearchBox_item react-datepicker-ignore-onclickoutside"
            onClick={(event) => toggleDatePicker(event)}
          >
            <div className="c-topSearchBox_item_icon">
              <i className="far fa-calendar-alt"></i>
            </div>
            <label htmlFor="date">
              {requiredDate && (
                <span className="me-1 text-danger">
                  <i className="fas fa-info-circle"></i>
                </span>
              )}
              <label htmlFor="mealCategory">
                {window.i18n.t(
                  "components.restaurants.top_search_box.top_searchbox.date.label",
                )}
              </label>
            </label>
            <p className="c-topSearchBox_ph">
              {startDate && endDate
                ? `${format(startDate, window.i18n.t("time.date"), { locale: getDateFnsLocale() })} - ${format(endDate, window.i18n.t("time.date"), { locale: getDateFnsLocale() })}`
                : `${window.i18n.t("components.restaurants.top_search_box.top_searchbox.date.option.default")}`}
            </p>
            <i className="fas fa-angle-down"></i>
            <div onClick={(e) => e.stopPropagation()}>
              <DatePicker
                locale={getDateFnsLocale()}
                weekStartsOn={getWeekStartsOn()}
                isClearable
                selected={startDate}
                onChange={handleDatesChanges}
                onMonthChange={(month: Date) => fetchAvailableDates(month)}
                ref={(_datePicker: DatePicker) =>
                  (datePicker.current = _datePicker)
                }
                customInput={<></>}
                minDate={new Date(minDate)}
                excludeDates={excludedDates}
                popperClassName="custom-react-datepicker"
                showPopperArrow={false}
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 5],
                    },
                  },
                ]}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                selectsRange
              />
            </div>
          </div>
          <div
            className="c-topSearchBox_item"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="c-topSearchBox_item_icon">
              <i className="fas fa-user-friends"></i>
            </div>
            <label htmlFor="guestsCount">
              {window.i18n.t(
                "components.restaurants.top_search_box.top_searchbox.guests_count.label",
              )}
            </label>
            <p className="c-topSearchBox_ph">
              {guestsCount
                ? `${guestsCount}`
                : `${window.i18n.t("components.restaurants.top_search_box.top_searchbox.guests_count.option.default")}`}
              {guestsCount > 0
                ? guestsCount > 1
                  ? ` ${window.i18n.t("components.restaurants.top_search_box.top_searchbox.guests_count.option.unit.is_plural")}`
                  : ` ${window.i18n.t("components.restaurants.top_search_box.top_searchbox.guests_count.option.unit.is_singular")}`
                : ""}
            </p>
            <i className="fas fa-angle-down"></i>
            <select
              id="guestsCount"
              className="c-topSearchBox_select"
              value={guestsCount}
              onChange={(event) => {
                const selectedGuestCount = event.target.value
                  ? parseInt(event.target.value)
                  : 0;
                setGuestsCount(selectedGuestCount);
                buildAndSetQueryString(
                  "guestsCount",
                  selectedGuestCount > 0 ? selectedGuestCount.toString() : null,
                );
              }}
              aria-label="guestsCount"
            >
              <option value=""></option>
              {Array.from({ length: maxGuestsCount }).map(
                (_option: null, index: number) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ),
              )}
            </select>
          </div>

          <div
            className="c-topSearchBox_item"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="c-topSearchBox_item_icon">
              <i className="far fa-clock"></i>
            </div>
            <label htmlFor="mealCategory">
              {requiredTime && (
                <span className="me-1 text-danger">
                  <i className="fas fa-info-circle"></i>
                </span>
              )}
              {window.i18n.t(
                "components.restaurants.top_search_box.top_searchbox.time.label",
              )}
            </label>
            <p className="c-topSearchBox_ph">
              {mealCategory
                ? window.i18n.t(`meal_categories.${mealCategory}`)
                : `${window.i18n.t("components.restaurants.top_search_box.top_searchbox.time.option.default")}`}
            </p>
            <i className="fas fa-angle-down"></i>
            <select
              id="mealCategory"
              className="c-topSearchBox_select"
              value={mealCategory}
              onChange={(event) => {
                setMealCategory(event.target.value as MealCategory);
                buildAndSetQueryString("mealCategory", event.target.value);
                setRequiredDate(!startDate && !endDate && !!event.target.value);
                setRequiredTime(
                  !!startDate && !!endDate && !event.target.value,
                );
              }}
              aria-label="mealCategory"
            >
              <option value=""></option>
              {mealCategories.map((option) => (
                <option key={option} value={option}>
                  {window.i18n.t(
                    `meal_categories.${option?.charAt(0) + option?.slice(1)}`,
                  )}
                </option>
              ))}
            </select>
          </div>

          <div className="c-topSearchBox_submit">
            <a
              href={`${window.i18n.locale_path}/restaurants${prefecture ? `/pref/${prefecture.uid}` : ""}${queryString ? `?${queryString}` : ""}`}
              aria-label="search_submit"
            >
              <button
                type="submit"
                className="pc-only c-topSearchBox_submitIcon"
                aria-label="search"
                onClick={doSearch}
              >
                <i className="fas fa-search"></i>
              </button>
              <button
                type="submit"
                className="sp-only btn btn-primary"
                aria-label="search"
                onClick={() => {
                  setLoading(true);
                  doSearch();
                }}
                disabled={loading}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm me-2" />
                ) : (
                  window.i18n.t(
                    "components.restaurants.top_search_box.top_searchbox.submit.button_text",
                  )
                )}
              </button>
            </a>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchBox;
