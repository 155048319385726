import React, { useState } from "react";

import { Button, Form, InputGroup, Modal } from "react-bootstrap";

import { isValidEmail } from "../../utils/validations";

type Props = {
  reservationUid: number;
};

const ReservationCancelButton: React.FC<Props> = ({ reservationUid }) => {
  const [showModal, setShowModal] = useState(false);
  const [emailInvalidMessage, setEmailInvalidMessage] = useState("");
  const [email, setEmail] = useState("");

  const handleClickCancellation = () => {
    // email が空欄の場合
    if (!email) {
      setEmailInvalidMessage(
        window.i18n.t(
          "components.user.instant_reservations.complete.reservation_cancel_button_field.error.email.blank",
        ),
      );
      return;
    }

    // email の形式が正しくない場合
    if (!isValidEmail(email)) {
      setEmailInvalidMessage(
        window.i18n.t(
          "components.user.instant_reservations.complete.reservation_cancel_button_field.error.email.invalid",
        ),
      );
      return;
    }

    window.location.href = `/user/reservations/${reservationUid}/cancellation/new?email=${encodeURIComponent(email)}`;
  };

  return (
    <>
      <Button
        className="btn-sm"
        variant="link"
        onClick={() => setShowModal(true)}
      >
        {window.i18n.t(
          "components.user.instant_reservations.complete.reservation_cancel_button_field.title",
        )}
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {window.i18n.t(
              "components.user.instant_reservations.complete.reservation_cancel_button_field.title",
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {window.i18n.t(
              "components.user.instant_reservations.complete.reservation_cancel_button_field.description",
            )}
          </p>
          <Form>
            <Form.Group className="mb-3" controlId="email">
              <InputGroup hasValidation>
                <Form.Control
                  className="mb-2"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  isInvalid={!!emailInvalidMessage}
                />
                <Form.Control.Feedback type="invalid">
                  {emailInvalidMessage}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            className="btn btn-sm"
            onClick={() => {
              setEmailInvalidMessage("");
              setEmail("");
              setShowModal(false);
            }}
          >
            {window.i18n.t(
              "components.user.instant_reservations.complete.default.cancel_button_text",
            )}
          </Button>
          <Button
            className="btn btn-sm"
            variant="outline-primary"
            onClick={handleClickCancellation}
          >
            {window.i18n.t(
              "components.user.instant_reservations.complete.reservation_cancel_button_field.submit.button_text",
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReservationCancelButton;
