import React from "react";
import { Form } from "react-bootstrap";
import { formControlContext, FormFieldState } from "./form_control_context";

// NOTE: I18n対応のため、この配列は外部から渡すようにする
//       config/locales/views.ja.yml or config/locales/views.en.yml の user.new_reservation_requests.checklist_labels を参照
type Props = {
  checklistLabels: string[];
};

const RequestChecklist: React.FC<Props> = ({ checklistLabels }) => {
  const context: FormFieldState = React.useContext(formControlContext);
  const [checkList, setCheckList] = React.useState<boolean[]>(
    [...Array(checklistLabels.length)].map(() => false),
  );

  const handleChange = (e) => {
    const changeIdx: number = checkList.indexOf(!e.target.checked);
    const changedList = checkList.map((c, idx) => (idx === changeIdx ? !c : c));
    setCheckList(changedList);
    context.setIsRequestChecked(!changedList.some((c) => !c));
  };

  return (
    <div className="c-rsvCheckout_checkbox">
      <ul>
        {checklistLabels.map((label, idx) => (
          <li key={idx}>
            <div className="c-agreeCb">
              <Form.Check
                id={`${idx}`}
                type="checkbox"
                label={label}
                onChange={(e) => handleChange(e)}
                disabled={context.isFormSending}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RequestChecklist;
