import React from "react";

import TipLogo5 from "../../../images/tips/tip_bottle_5.png";
import TipLogo10 from "../../../images/tips/tip_bottle_10.png";
import TipLogo15 from "../../../images/tips/tip_bottle_15.png";

const TipImage: React.FC<{ rate: number }> = ({ rate }) => {
  return (
    <>
      {rate === 0.05 && <img src={TipLogo5} alt="5%" height={120} />}
      {rate === 0.1 && <img src={TipLogo10} alt="10%" height={120} />}
      {rate === 0.15 && <img src={TipLogo15} alt="15%" height={120} />}
    </>
  );
};

export default TipImage;
